import dayjs, {Dayjs, dayjsBase} from "@shared/services/dayjs";

export type AvailabilitySlot = {
  start: Dayjs | string;
  end: Dayjs | string;
  duration: number;
  name?: string;
  color?: string;
  _id?: string;
};

export const getSlotsEnd = (slots?: Array<AvailabilitySlot>) => {
  return slots && slots.length > 0
    ? dayjsBase.max(...slots.map((s) => dayjs(s.end)))?.toISOString()
    : undefined;
};

export const getSlotsStart = (slots?: Array<AvailabilitySlot>) => {
  return slots && slots.length > 0
    ? dayjsBase.min(...slots.map((s) => dayjs(s.start)))?.toISOString()
    : undefined;
};

/**
 * Returns the earliest start and latest end of all slots given
 */
export const getSlotsStartAndEnd = (slots: Array<AvailabilitySlot>, defaultStart?: string) => {
  return {start: getSlotsStart(slots) || defaultStart, end: getSlotsEnd(slots)};
};

export const slotEndIsBeforeBeginning = (slot: AvailabilitySlot) =>
  dayjs(slot.start).toDate() > dayjs(slot.end).toDate();

export const hasSameStartAndEnd = (slotA: AvailabilitySlot, slotB: AvailabilitySlot): boolean =>
  dayjs(slotA.start).isSame(slotB.start) && dayjs(slotA.end).isSame(slotB.end);

export const slotOverlapsAnOtherOne = (
  slot: AvailabilitySlot,
  availabilitySlots?: Array<AvailabilitySlot> | undefined,
  ignoreThisSlot?: AvailabilitySlot
) => {
  if (!availabilitySlots) return false;

  for (let existingSlot of availabilitySlots) {
    if (ignoreThisSlot && hasSameStartAndEnd(existingSlot, ignoreThisSlot)) {
      continue;
    }

    // Check if the slot overlaps an other slot
    if (
      dayjs(slot.start).toDate() < dayjs(existingSlot.end).toDate() &&
      dayjs(slot.end).toDate() > dayjs(existingSlot.start).toDate()
    ) {
      return true;
    }
  }

  return false;
};
